import axios from 'src/utils/axios';
import { LoginRequest } from 'src/models/api/loginRequest';
import { StringOrNumber } from 'src/types/common';
import { User, userResponseToUser } from 'src/models/user';
import { LoginResult, loginResponseToLoginResult } from 'src/models/loginResult';
import { LoginResponse } from 'src/models/api/loginResponse';
import { UserUpdateParams } from 'src/models/api/userRequest';
import { UserResponse } from 'src/models/api/userResponse';

export default {
  async login(data: LoginRequest): Promise<LoginResult> {
    let response = null;
    try {
      const tmp = await axios.post<LoginResponse>('/users/login', data);
      response = loginResponseToLoginResult(tmp.data);
    } catch (e) {
      throw new Error('ユーザーIDかパスワードが間違っています');
    }
    return response;
  },
  async logout(): Promise<{}> {
    return axios.delete('/users/logout');
  },
  async getMe(): Promise<User> {
    const response = await axios.get<UserResponse>('/users/me');
    return userResponseToUser(response.data);
  },
  async adminGetUsers(): Promise<User[]> {
    const response = await axios.get<UserResponse[]>('/admin/users');
    return response.data.map((e) => userResponseToUser(e));
  },
  async adminShowUser(id: StringOrNumber): Promise<User> {
    const response = await axios.get<UserResponse>(`/admin/users/${id}`);
    return userResponseToUser(response.data);
  },
  async adminUpdateUser(id: StringOrNumber, params: UserUpdateParams): Promise<User> {
    const response = await axios.put<UserResponse>(`/admin/users/${id}`, params);
    return userResponseToUser(response.data);
  },
  async adminDeleteUser(id: StringOrNumber): Promise<User> {
    const response = await axios.delete<UserResponse>(`/admin/users/${id}`);
    return userResponseToUser(response.data);
  },
};
