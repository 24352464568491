export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const LOGIN_PATH = '/login';
export const APP_LANDING_PATH = '/app/detections';
export const APP_DETECTION_DETAIL_PATH = 'app/detections/detection-details';

export const ROLE_OPTIONS = [
  {
    key: 100,
    value: '管理者',
  },
  {
    key: 200,
    value: '編集者',
  },
  {
    key: 300,
    value: 'メンバー',
  },
];

export type DETECTION_KIND =
  'pothole' |
  'crack' |
  'joint_damage';

export const DETECTION_KIND_MAP: Record<DETECTION_KIND, string> = {
  joint_damage: 'ジョイント損傷',
  pothole: 'ポットホール',
  crack: 'ヒビ',
};

export const DETECT_IMAGE_WIDTH_DEFAULT = 1920;
export const DETECT_IMAGE_HEIGHT_DEFAULT = 1080;
export const DETECT_MASK_WIDTH_DEFAULT = 1920;
export const DETECT_MASK_HEIGHT_DEFAULT = 512;

export const MOVIE_PF_URL = 'https://movie-pf.land-maintenance.com';
