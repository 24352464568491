import mock from 'src/utils/mock';
import type { DetectionTransactionResponse } from 'src/models/api/detectionTransactionResponse';

const detectionTransactions: DetectionTransactionResponse[] = [
  {
    id: 'ece2c123fad30cbbff8d060',
    detection_transaction_view_group_id: '4',
    num: 1,
    start_ts: '2021-02-24 00:00:00+0900',
    finish_ts: '2021-02-24 01:50:00+0900',
    detection_kinds: 'joint_damage',
  },
  {
    id: 'ece2c1be7996d1549d94e34',
    detection_transaction_view_group_id: '3',
    num: 1,
    start_ts: '2021-02-23 23:05:00+0900',
    finish_ts: null,
    detection_kinds: 'pothole,crack',
  },
  {
    id: 'ece2c0d16f70bff2cf86cd8',
    detection_transaction_view_group_id: '2',
    num: 1,
    start_ts: '2021-02-23 23:05:00+0900',
    finish_ts: '2021-02-23 23:30:00+0900',
    detection_kinds: 'pothole,crack',
  },
  {
    id: '5ece2c077e39da27658aa8a9',
    detection_transaction_view_group_id: '1',
    num: 1,
    start_ts: '2021-02-23 22:59:30+0900',
    finish_ts: '2021-02-23 23:10:00+0900',
    detection_kinds: 'pothole',
  },
];

mock.onGet('/detection-transactions').reply(200, detectionTransactions);
