import { LoginResponse } from 'src/models/api/loginResponse';
import { User, userResponseToUser } from './user';

export type LoginResult = Omit<LoginResponse, 'user'> & {
  user: User;
};

export function loginResponseToLoginResult(response: LoginResponse): LoginResult {
  return {
    ...response,
    user: userResponseToUser(response.user),
  };
}
