import mock from 'src/utils/mock';
import type { DetectionDetailResponse } from 'src/models/api/detectionDetailResponse';

const detectionDetails: DetectionDetailResponse[] = [
  {
    id: '1',
    detection_detail_view_group_id: '1',
    detection_transaction_id: 'ece2c0d16f70bff2cf86cd8',
    detection_movie_id: 'dummy',
    movie_ts_msec_offset: 0,
    img_ts: '2021-02-23 23:05:00+0900',
    img_path: '/hoge/fuga/piyo.jpg',
    thumbnail_path: '/testdir1/testdir1-2/test1-1-1.jpg',
    lat: '35.682595',
    lon: '139.777764',
    road_name_disp: '高速都心環状線',
    road_name: '高速都心環状線',
    direction: '外',
    place_name: 'main_line',
    kp: '0.5',
    bikou: '',
    is_hidden: false,
    detection_detail_entries: [
      {
        id: '101',
        detection_detail_id: '1',
        detection_kind: 'pothole',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 1000,
        dilated_pixel_sum: 1200,
        contour_num: 1,
        contour_areas: [1200],
        contour_area_sum: 1200,
      }
    ],
  },
  {
    id: '2',
    detection_detail_view_group_id: '2',
    detection_transaction_id: 'ece2c0d16f70bff2cf86cd8',
    detection_movie_id: 'dummy',
    movie_ts_msec_offset: 10000,
    img_ts: '2021-02-23 23:05:10+0900',
    img_path: '/hoge/fuga/piyo.jpg',
    thumbnail_path: '/testdir1/testdir1-2/test1-1-2.jpg',
    lat: '35.682098',
    lon: '139.777464',
    road_name_disp: '高速都心環状線',
    road_name: '高速都心環状線',
    direction: '外',
    place_name: 'main_line',
    kp: '0.8',
    bikou: '',
    is_hidden: false,
    detection_detail_entries: [
      {
        id: '201',
        detection_detail_id: '2',
        detection_kind: 'pothole',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 1000,
        dilated_pixel_sum: 1200,
        contour_num: 1,
        contour_areas: [1200],
        contour_area_sum: 1200,
      },
      {
        id: '202',
        detection_detail_id: '2',
        detection_kind: 'crack',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 2000,
        dilated_pixel_sum: 2300,
        contour_num: 3,
        contour_areas: [800, 800, 800],
        contour_area_sum: 2400,
      }
    ],
  },
  {
    id: '3',
    detection_detail_view_group_id: '3',
    detection_transaction_id: 'ece2c0d16f70bff2cf86cd8',
    detection_movie_id: 'dummy',
    movie_ts_msec_offset: 20000,
    img_ts: '2021-02-23 23:05:20+0900',
    img_path: '/hoge/fuga/piyo.jpg',
    thumbnail_path: '/testdir1/testdir1-2/test1-1-1.jpg',
    lat: '35.681724',
    lon: '139.777193',
    road_name_disp: '高速都心環状線',
    road_name: '高速都心環状線',
    direction: '外',
    place_name: 'main_line',
    kp: '1.0',
    bikou: '',
    is_hidden: false,
    detection_detail_entries: [
      {
        id: '301',
        detection_detail_id: '3',
        detection_kind: 'crack',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 1000,
        dilated_pixel_sum: 1200,
        contour_num: 1,
        contour_areas: [1200],
        contour_area_sum: 1200,
      }
    ],
  },
  {
    id: '4',
    detection_detail_view_group_id: '4',
    detection_transaction_id: 'ece2c0d16f70bff2cf86cd8',
    detection_movie_id: 'dummy',
    movie_ts_msec_offset: 30000,
    img_ts: '2021-02-23 23:05:30+0900',
    img_path: '/hoge/fuga/piyo.jpg',
    thumbnail_path: '/testdir1/testdir1-2/test1-1-2.jpg',
    lat: '35.678438',
    lon: '139.775283',
    road_name_disp: '高速都心環状線',
    road_name: '高速都心環状線',
    direction: '外',
    place_name: 'main_line',
    kp: '1.2',
    bikou: '',
    is_hidden: false,
    detection_detail_entries: [
      {
        id: '401',
        detection_detail_id: '4',
        detection_kind: 'crack',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 1000,
        dilated_pixel_sum: 1200,
        contour_num: 1,
        contour_areas: [1200],
        contour_area_sum: 1200,
      }
    ],
  },
  {
    id: '5',
    detection_detail_view_group_id: '4',
    detection_transaction_id: 'ece2c0d16f70bff2cf86cd8',
    detection_movie_id: 'dummy',
    movie_ts_msec_offset: 40000,
    img_ts: '2021-02-23 23:05:40+0900',
    img_path: '/hoge/fuga/piyo.jpg',
    thumbnail_path: '/testdir1/testdir1-2/test1-1-1.jpg',
    lat: '35.674499',
    lon: '139.772794',
    road_name_disp: '高速都心環状線',
    road_name: '高速都心環状線',
    direction: '外',
    place_name: 'main_line',
    kp: '1.5',
    bikou: '',
    is_hidden: false,
    detection_detail_entries: [
      {
        id: '501',
        detection_detail_id: '5',
        detection_kind: 'pothole',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 1000,
        dilated_pixel_sum: 1200,
        contour_num: 1,
        contour_areas: [1200],
        contour_area_sum: 1200,
      },
      {
        id: '502',
        detection_detail_id: '5',
        detection_kind: 'crack',
        mask_img_path: '/some/mask/path.png',
        detected_pixel_sum: 2000,
        dilated_pixel_sum: 2300,
        contour_num: 3,
        contour_areas: [800, 800, 800],
        contour_area_sum: 2400,
      }
    ],
  },
];

mock.onGet(/\/detection-transactions\/\d+\/detection-details/).reply(200, detectionDetails);
