import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { LOGIN_PATH } from 'src/constants';

interface AdminAuthGuardProps {
  children?: ReactNode;
}

const AdminAuthGuard: FC<AdminAuthGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to={LOGIN_PATH} />;
  }
  if (!user.has_role_admin) {
    return <Redirect to={LOGIN_PATH} />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default AdminAuthGuard;
