import AxiosMockAdapter from 'axios-mock-adapter';
import axios from 'axios';

export const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const axiosMockAdapter = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 });
export default axiosMockAdapter;
