import axios from 'axios';

const defaultAxiosInstance = axios.create({
  baseURL: '/api/v1',
});
export default defaultAxiosInstance;

export function setAxiosAuthorizationHeader(accessToken: string): void {
  defaultAxiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export function removeAxiosAuthorizationHeader(): void {
  delete defaultAxiosInstance.defaults.headers.common.Authorization;
}
