import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { APP_LANDING_PATH, APP_DETECTION_DETAIL_PATH } from 'src/constants';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const getRedirectPathFromExtParams = (): string | null => {
    const extParams = localStorage.getItem('extParams');
    if (!extParams) { return null; }
    localStorage.removeItem('extParams');
    let ret = null;
    try {
      const extParamsObj = JSON.parse(extParams);
      if (extParamsObj.tid && extParamsObj.detail_id) {
        ret = `${APP_DETECTION_DETAIL_PATH}?tid=${extParamsObj.tid}&detail_id=${extParamsObj.detail_id}`;
      }
    } catch (e) {}
    return ret;
  }

  if (isAuthenticated) {
    const redirectPath = getRedirectPathFromExtParams() || APP_LANDING_PATH;
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
