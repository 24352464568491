import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { LOGIN_PATH } from 'src/constants';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  useEffect(() => {
    const handleExtParams = () => {
      if (isAuthenticated) {
        return;
      }
      const params = new URLSearchParams(location.search);
      if (params.has('tid') && params.has('detail_id')) {
        const extParams = {
          tid: params.get('tid'),
          detail_id: params.get('detail_id')
        };
        localStorage.setItem('extParams', JSON.stringify(extParams));
      }
    }

    handleExtParams();
    // 初回しか実行しないため(componentDidMount)
    // eslint-disable-next-line
  }, [])

  if (!isAuthenticated) {
    return <Redirect to={LOGIN_PATH} />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
