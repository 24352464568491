import { UserResponse } from 'src/models/api/userResponse';

function getRoleDisp(user: UserResponse): string {
  if (user.has_role_super_admin) {
    return '★管理者';
  }
  if (user.has_role_admin) {
    return '管理者';
  }
  if (user.has_role_editor) {
    return '編集者';
  }
  return 'メンバー';
}

export type User = UserResponse & {
  roleDisp: string;
};

export function userResponseToUser(response: UserResponse): User {
  return {
    ...response,
    roleDisp: getRoleDisp(response),
  };
}
