import DateFnsUtils from '@date-io/date-fns';
import localeJa from 'date-fns/locale/ja';
import format from 'date-fns/format';
import { DatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';

class DateFnsUtilsJa extends DateFnsUtils {
  getYearText(date: Date) {
    return format(date, 'yyyy年', { locale: this.locale });
  }

  getDatePickerHeaderText(date: Date): string {
    return format(date, 'MMMd日', { locale: this.locale });
  }

  getCalendarHeaderText(date: Date): string {
    return format(date, 'yyyy年 MMM', { locale: this.locale });
  }
}

export const getMuiPickersUtilsProviderLocaleJaProps = () => {
  return {
    utils: DateFnsUtilsJa,
    locale: localeJa,
  };
};

export const datePickerDefaultOptions: Partial<DatePickerProps> = {
  inputVariant: 'outlined',
  variant: 'inline',
  format: 'yyyy/MM/dd',
  autoOk: true,
  // variant: inline でない場合は右下にボタンが出るが、それの文言を変えるには以下.
  // okLabel: '決定',
  // cancelLabel: 'キャンセル',
};

export default {
  getMuiPickersUtilsProviderLocaleJaProps
};
