import React from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Hidden,
  Typography,
  IconButton, SvgIcon, Tooltip
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { LogOut as LogOutIcon } from 'react-feather';

const Account: FC = () => {
  const history = useHistory();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('ログアウトに失敗しました', {
        variant: 'error'
      });
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Hidden smDown>
        <Typography variant="h6" color="inherit">
          {user.display_name}
        </Typography>
      </Hidden>
      <Tooltip title="ログアウト">
        <IconButton color="inherit" onClick={handleLogout}>
          <SvgIcon fontSize="small">
            <LogOutIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Account;
